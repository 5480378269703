<template>
  <TModal
    title="Role"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="create()"
    :creating="loading"
  >
    <template #actions>
      <div></div>
    </template>
    <SSelectRole
      :value.sync="role_id"
      class="mb-3"
      :prepend="[]"
      label="Select role"
    />
    <TButton
      content="Add"
      icon="cil-plus"
      class="float-right mb-3"
      :options="{ disabled: !role_id }"
      @click="createRoleSpType"
    />
    <TTableSimple
      :fields="itemFields"
      :items="supportType.role_support_types"
      noResult
    >
      <template #_="{ item }">
        <td>
          <TButtonRemove @click="removeType(item.id)" />
        </td>
      </template>
      <template #role="{ item }">
        <td>
          <SMessageRole :id="item.role_id" />
        </td>
      </template>
    </TTableSimple>
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    supportType: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      loading: false,
      role_id: "",
      itemFields: [
        {
          key: "_",
          label: "#",
          _style: "width: 40px",
        },
        {
          key: "role",
          label: "Role",
          _style: "min-width: 150px",
          placeholder: "Description",
        },
      ],
    };
  },
  methods: {
    createRoleSpType() {
      this.$store
        .dispatch("helper.role_support_types.create", {
          role_id: this.role_id,
          support_type_id: this.supportType.id,
        })
        .then(() => {
          this.role_id = "";
          this.$emit("updated");
        });
    },
    removeType(id) {
      this.$store
        .dispatch("helper.role_support_types.delete", id)
        .then((data) => {
          this.$emit("updated", data);
        });
    },
  },
};
</script>
