<template>
  <div>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="helper.support_types"
      resource="support-types"
      creatable
      @click-create="showCreateType = true"
      class="mx-auto"
      style="max-width: 800px"
      noFilter
    >
      <template #append-actions="{ item }">
        <CCol class="d-flex px-2" col="12">
          <TButtonEdit @click="editItem(item)" />
          <TButton
            icon="cil-list"
            size="sm"
            tooltip="Authorization"
            variant="ghost"
            @click="editPermission(item)"
          />
          <TButtonDelete @click="removeType(item.id)" />
        </CCol>
      </template>

      <template #name="{ item }">
        <td>
          <TMessageText :value="item.name" />
        </td>
      </template>
      <template #parent="{ item }">
        <td>
          <TMessageText v-if="item.parent" :value="item.parent.name" />
        </td>
      </template>
      <template #role="{ item }">
        <td>
          <div v-for="role in item.role_support_types" :key="role.id">
            <SMessageRole :id="role.role_id" />
          </div>
        </td>
      </template>
    </TTableAdvance>
    <SupportTypeModal @updated="fetchSupports" :show.sync="showCreateType" />
    <SupportTypeModal
      @updated="fetchSupports"
      :supportType="detail"
      :show.sync="showEditType"
    />
    <RoleSupportTypeModal
      @updated="fetchRoleSpType"
      :supportType="detail"
      :show.sync="showPermission"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RoleSupportTypeModal from "./RoleSupportTypeModal.vue";
import SupportTypeModal from "./SupportTypeModal.vue";

export default {
  components: { SupportTypeModal, RoleSupportTypeModal },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px" },
        { key: "id", label: "Id", style: "min-width: 300px" },
        { key: "name", label: "Type", _style: "width: 60%" },
        { key: "role", label: "Role", _style: "width: 60%" },
      ],
      filter: {},
      showCreateType: false,
      showEditType: false,
      showPermission: false,
    };
  },
  created() {
    this.$store.dispatch("helper.support_types.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      list: "helper.support_types.list",
      detail: "helper.support_types.detail",
    }),
  },
  methods: {
    removeType(id) {
      this.$store.dispatch("helper.support_types.delete", id);
    },
    fetchSupports() {
      return this.$store.dispatch("helper.support_types.fetch");
    },
    editItem(item) {
      this.$store.commit("helper.support_types.select", item.id);
      this.showEditType = true;
    },
    editPermission(item) {
      this.$store.commit("helper.support_types.select", item.id);
      this.showPermission = true;
    },
    fetchRoleSpType() {
      this.fetchSupports().then(() => {
        this.$store.commit("helper.support_types.select", this.detail.id);
      });
    },
  },
};
</script>
